import { HttpHeaders } from "@angular/common/http";
import { Institution } from "src/json_entities/generic/Institution";
import { environment, storage_var} from '../environments/environment';

export class APIBase{
    url:string;
    base:string='core';
    
    constructor(){
        
    }

    getUrl(): string{
        var isonline=localStorage.getItem(storage_var.isonline);
        var institution=JSON.parse(localStorage.getItem(storage_var.institution)) as Institution;
        if(isonline!=null){
          if(isonline==='oui'){
            if(institution!=null) this.url=institution.onlineIp+':'+institution.onlinePort+'/'+environment.base;
          }else{
            if(environment.mode==='prod' || environment.mode==='local'){
             this.url=environment.local_conf+':'+environment.local_port+'/'+environment.base;
            }else{
              this.url=environment.local_ip+':'+environment.local_port+'/'+environment.base;
            }
          }
        }else{
            if(environment.mode==='prod' || environment.mode==='local'){
              this.url=environment.local_conf+':'+environment.local_port+'/'+environment.base;
             }else{
               this.url=environment.local_ip+':'+environment.local_port+'/'+environment.base;
             }
        }
        return this.url;
      }

      
    getPingUrl(): string{
      var isonline=localStorage.getItem(storage_var.isonline);
      var institution=JSON.parse(localStorage.getItem(storage_var.institution)) as Institution;
      if(isonline!=null){
        if(isonline==='oui'){
          if(institution!=null) this.url=environment.online_ip+':'+institution.onlinePort+'/';
        }else{
          this.url=environment.local_ip+':'+environment.local_port+'/';
        } 
      }else{
        if(institution!=null) 
          this.url=environment.online_ip+':'+institution.onlinePort+'/';
        else this.url=this.url=environment.local_ip+':'+environment.local_port+'/';
      }
      return this.url;
    }

      getHeaders(withToken:boolean): {}{
        var httpOptions;
        if(withToken){
            var token=localStorage.getItem(storage_var.token);
            httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer `+token,
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET, POST, OPTIONS',
                    'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With'
                })
            }
        }else{
            httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET, POST, OPTIONS',
                    'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With'
                })
            }
        }
        return httpOptions;
      }


      getPlainTexteHeaders(withToken:boolean): {}{
        var httpOptions;
        if(withToken){
            var token=localStorage.getItem(storage_var.token);
            httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type':  'text/plain; charset=utf-8',
                    'Authorization': `Bearer `+token,
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET, POST, OPTIONS',
                    'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With'
                }),  responseType: 'text'
            }
        }else{
            httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'text/plain; charset=utf-8',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET, POST, OPTIONS',
                    'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With'
                }),  responseType: 'text'
            }
        }
        return httpOptions;
      }

      stringify(entity:any):string{
        return JSON.stringify(entity);
      }
}