import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { ErrorManager} from '../../errors/errorManager';
import { APIBase } from '../APIBase';
import { CommonService } from '../common/common.service';
import { storage_var } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class CoreService<T> extends APIBase{

  constructor(public http: HttpClient, public commonService:CommonService, public errorManager: ErrorManager) {
    super();
  }

//===============================================Generic================================================
getEntities(suffixRoute:string,withHeader:boolean): Observable<T[]> {
    return this.http
        .get<T[]>(this.getUrl() + this.base+suffixRoute, this.getHeaders(withHeader))
        .pipe(
            retry(0),
            catchError(this.errorManager.handleError)
        );
   }

   getEntity(suffixRoute:string,withHeader:boolean): Observable<T> {
    return this.http
        .get<T>(this.getUrl() + this.base+suffixRoute, this.getHeaders(withHeader))
        .pipe(
            retry(0),
            catchError(this.errorManager.handleError)
        );
    } 

   editEntity(entity: T,suffixRoute:string,withHeader:boolean): Observable<T> {
    return this.http
        .post<T>(this.getUrl() + this.base+suffixRoute,this.stringify(entity), this.getHeaders(withHeader))
        .pipe(
            retry(0),
            catchError(this.errorManager.handleError)
        );
   }

   createEntity(entity: T,suffixRoute:string,withHeader:boolean): Observable<T> {
    return this.http
        .post<T>(this.getUrl() + this.base+suffixRoute,this.stringify(entity), this.getHeaders(withHeader))
        .pipe(
            retry(0),
            catchError(this.errorManager.handleError)
        );
   }
   createEntities(entities: T[],suffixRoute:string,withHeader:boolean): Observable<T> {
    return this.http
        .post<T>(this.getUrl() + this.base+suffixRoute,this.stringify(entities), this.getHeaders(withHeader))
        .pipe(
            retry(0),
            catchError(this.errorManager.handleError)
        );
   }

   deleteEntity(entity: T,suffixRoute:string,withHeader:boolean): Observable<T> {
    return this.http
        .post<T>(this.getUrl() + this.base+suffixRoute,this.stringify(entity), this.getHeaders(withHeader))
        .pipe(
            retry(0),
            catchError(this.errorManager.handleError)
        );
   }

   voidAction(suffixRoute:string,withHeader:boolean): Observable<any> {
    return this.http
    .get<any>(this.getUrl() + this.base+suffixRoute, this.getHeaders(withHeader))
    .pipe(
        retry(0),
        catchError(this.errorManager.handleError)
    );
   }

   setBase(base:string){
      this.base=base;
   }

   getCommonService():CommonService{
        return this.commonService;
   }

   //=========================================================generate file==========================================================
   getFile(suffixRoute:string): Observable<any> {
    var httpOptions;
        var token=localStorage.getItem(storage_var.token);
        httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': `Bearer `+token,
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET, POST, OPTIONS',
                'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With'
            }),
            'responseType'  : 'arraybuffer' as 'json'
        }
    
    return this.http.get<any>(this.getUrl() + this.base+suffixRoute, httpOptions);
    }

    getFilePost(entity: any,suffixRoute:string): Observable<any> {
        var httpOptions;
            var token=localStorage.getItem(storage_var.token);
            httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer `+token,
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET, POST, OPTIONS',
                    'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With'
                }),
                'responseType'  : 'arraybuffer' as 'json'
            }
        
        return this.http.post<any>(this.getUrl() + this.base+suffixRoute, this.stringify(entity), httpOptions);
    }
}
