export class AccesParent {
    public static BASIC = "BASIC";
    public static PREMIUM = "PREMIUM";
    public static GOLD = "GOLD";
    public static PARENT = "PARENT";


    public static accesPayant():string[]{
        let all= [AccesParent.BASIC,AccesParent.PREMIUM,AccesParent.GOLD];
        return all;
    }

    public static accesPremiumAndGold():string[]{
        let all= [AccesParent.PREMIUM,AccesParent.GOLD];
        return all;
    }
}