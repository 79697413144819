import { DatePipe } from '@angular/common';
import { Acces } from 'src/app/security/Acces';
import { AccesParent } from '../util/AccesParent';
import { Roles } from '../util/Roles';
export abstract class Base{

      id:any;

      dateCreation: string;
 

      dateModification: string;
 
      description:string=null;
 
      etat:number=0;
 
      synchronize:boolean=false;
 
      deleted:boolean=false;
 
      archived:boolean=false;

      idcreateur:string;

      constructor(){
            var  datePipe: DatePipe=new DatePipe("en-US")
            this.dateCreation = datePipe.transform(new Date(), "yyyy-MM-dd'T'HH:mm:ss");
            this.dateModification = datePipe.transform(new Date(), "yyyy-MM-dd'T'HH:mm:ss");
        }
 
      public getAcces(userRoles:string[]):Acces{
            let acces=new Acces();
            acces.read=userRoles.some(r=> Roles.allRoles().includes(r));
            acces.detail=acces.read;
            acces.create=userRoles.includes(Roles.MANAGER);
            acces.edit=userRoles.includes(Roles.MANAGER);
            acces.delete=userRoles.includes(Roles.MANAGER);
            acces.print=userRoles.includes(Roles.MANAGER);
            return acces;
      }

      public getAccesParent(userRoles:string[]):Acces{
            let acces=new Acces();
            acces.read=userRoles.includes(AccesParent.PARENT);
            acces.detail=acces.read;
            acces.create=false;
            acces.edit=false;
            acces.delete=false;
            acces.print=false;
            return acces;
      }
 
 }