import { Component, OnInit, OnDestroy, enableProdMode } from '@angular/core';
import { Globalization } from '@ionic-native/globalization/ngx';
import { TranslateService } from '@ngx-translate/core';

import { NavController, Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import {environment, storage_var } from '../environments/environment';
import { Roles } from 'src/json_entities/util/Roles';
import { CommonService } from 'src/api/common/common.service';
import { StringListService } from './service/string-list.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AnneeScolaire } from 'src/json_entities/core/AnneeScolaire';
import { Etablissement } from 'src/json_entities/core/Etablissement';
import { UserService } from './service/user.service';
if(environment.mode=="prod"||environment.mode=="local"){
  enableProdMode();
}

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy  {
  public anneeScolaire:AnneeScolaire;
  public etablissement:Etablissement;
  public mainRole:string=null;
  public isAuth:boolean=false;
  public userId: string;
  public parentMenu = [];
  public managerMenu = [];

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private globalization: Globalization, 
    public navCtrl: NavController,
    private commonService:CommonService,
    private stringListService:StringListService,
    private userService:UserService,
    private translate: TranslateService
  ) {
    var langue=this.translate.getBrowserLang();
    if(langue==="fr" ||  langue==="en" )
      translate.use(langue);
    else  translate.use("en");
    this.initializeApp();
  }

  
  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }



  ngOnInit() {
    this.anneeScolaire=JSON.parse(localStorage.getItem(storage_var.anneescolaire)) as AnneeScolaire;
    this.etablissement=JSON.parse(localStorage.getItem(storage_var.etablissement)) as Etablissement;
    this.userId=localStorage.getItem(storage_var.userId);
    //this.mainRole=localStorage.getItem(storage_var.mainRole);
    //this.isAuth=true;
    if((localStorage.getItem(storage_var.token)!==null) && (localStorage.getItem(storage_var.token)!==undefined)){
      this.isAuth=true;
      this.mainRole=localStorage.getItem(storage_var.mainRole);
      if(this.mainRole==="PARENT" || this.mainRole==="ELEVE")
      this.getParentRoles(this.userId);
      else  this.getUserRoles(this.userId);
      this.goToHome();
    }


    if(this.mainRole!=="PARENT" && this.mainRole!=="ELEVE"){
      this.userService.getEntity("/user/findById/"+this.userId,true).subscribe(response=>{
          if(!response.enable){
            this.logOut();
          }
      }, (e: HttpErrorResponse) => {
       // this.commonService.presentAlert(CommonService.WARNING,'Err '+this.commonService.getServerMsg(e));
      });
    }

    this.commonService.getIdAnneeCourante().subscribe(response =>{
      if(this.anneeScolaire!==null && response !== this.anneeScolaire.id) this.commonService.presentAlert(CommonService.WARNING,"L\'année scolaire en cours a été changé par le responsable d\'établissement. Veuillez vous déconnecter et vous reconnecter pour rester synchroniser.");
    }, (e: HttpErrorResponse) => {console.log("Error getIdAnneeCourante ")});

        //=================================== MENU PARENT=====================
    this.translate.get('manager.menuparent.home').subscribe(value => {
      this.parentMenu.push({
        title: value,
        url: '/tabs-parent',
        icon: 'home-outline'
      })
    });
    this.translate.get('manager.menuparent.etab').subscribe(value => {
      this.parentMenu.push({
        title: value,
        url: '/petablissements',
        icon: 'list'
      })
    });
    this.translate.get('manager.menuparent.as').subscribe(value => {
      this.parentMenu.push({
        title: value,
        url: '/panneescolaires',
        icon: 'list'
      })
    });
    this.translate.get('manager.menuparent.ps').subscribe(value => {
      this.parentMenu.push({
        title: value,
        url: '/pperiodes',
        icon: 'list'
      })
    });
    this.translate.get('manager.menuparent.batiments').subscribe(value => {
      this.parentMenu.push({
        title: value,
        url: '/pbatiments',
        icon: 'business-outline'
      })
    });
    this.translate.get('manager.menuparent.salleclasse').subscribe(value => {
      this.parentMenu.push({
        title: value,
        url: '/psalleclasses',
        icon: 'list'
      })
    });
    this.translate.get('manager.menuparent.cycles').subscribe(value => {
      this.parentMenu.push({
        title: value,
        url: '/pcycles',
        icon: 'list'
      })
    });
    this.translate.get('manager.menuparent.niveaux').subscribe(value => {
      this.parentMenu.push({
        title: value,
        url: '/pniveaux',
        icon: 'list'
      })
    });
    this.translate.get('manager.menuparent.proupem').subscribe(value => {
      this.parentMenu.push({
        title: value,
        url: '/pgroupematiere',
        icon: 'list'
      })
    });
    this.translate.get('manager.menuparent.matiere').subscribe(value => {
      this.parentMenu.push({
        title: value,
        url: '/pmatieres',
        icon: 'library-outline'
      })
    });
    this.translate.get('manager.menuparent.typef').subscribe(value => {
      this.parentMenu.push({
        title: value,
        url: '/ptypefrais',
        icon: 'list'
      })
    });
    this.translate.get('manager.menuparent.eleves').subscribe(value => {
      this.parentMenu.push({
        title: value,
        url: '/peleves',
        icon: 'people-circle-outline'
      })
    });

    

    //===================================MENU MANAGER=====================
    this.translate.get('manager.menumanager.home').subscribe(value => {
      this.managerMenu.push({
        title: value,
        url: '/tabs-manager',
        icon: 'home-outline'
      })
    });
    this.translate.get('manager.menumanager.etab').subscribe(value => {
      this.managerMenu.push({
        title: value,
        url: '/metablissements',
        icon: 'list'
      })
    });
    this.translate.get('manager.menumanager.as').subscribe(value => {
      this.managerMenu.push({
        title: value,
        url: '/manneescolaires',
        icon: 'list'
      })
    });
    this.translate.get('manager.menumanager.ps').subscribe(value => {
      this.managerMenu.push({
        title: value,
        url: '/mperiodes',
        icon: 'list'
      })
    });
    this.translate.get('manager.menumanager.batiments').subscribe(value => {
      this.managerMenu.push({
        title: value,
        url: '/mbatiments',
        icon: 'business-outline'
      })
    });
    this.translate.get('manager.menumanager.salleclasse').subscribe(value => {
      this.managerMenu.push({
        title: value,
        url: '/msalleclasses',
        icon: 'list'
      })
    });
    this.translate.get('manager.menumanager.cycles').subscribe(value => {
      this.managerMenu.push({
        title: value,
        url: '/mcycles',
        icon: 'list'
      })
    });
    this.translate.get('manager.menumanager.niveaux').subscribe(value => {
      this.managerMenu.push({
        title: value,
        url: '/mniveaux',
        icon: 'list'
      })
    });
    this.translate.get('manager.menumanager.proupem').subscribe(value => {
      this.managerMenu.push({
        title: value,
        url: '/mgroupematiere',
        icon: 'list'
      })
    });
    this.translate.get('manager.menumanager.matiere').subscribe(value => {
      this.managerMenu.push({
        title: value,
        url: '/mmatieres',
        icon: 'library-outline'
      })
    });
    this.translate.get('manager.menumanager.eleves').subscribe(value => {
      this.managerMenu.push({
        title: value,
        url: '/meleves',
        icon: 'people-circle-outline'
      })
    });
    this.translate.get('manager.menumanager.enseignants').subscribe(value => {
      this.managerMenu.push({
        title: value,
        url: '/menseignants',
        icon: 'people-outline'
      })
    });
    this.translate.get('manager.menumanager.typepersonnel').subscribe(value => {
      this.managerMenu.push({
        title: value,
        url: '/mtypepersonnel',
        icon: 'list'
      })
    });
    this.translate.get('manager.menumanager.personnels').subscribe(value => {
      this.managerMenu.push({
        title: value,
        url: '/mpersonnels',
        icon: 'people-outline'
      })
    });
    this.translate.get('manager.menumanager.parents').subscribe(value => {
      this.managerMenu.push({
        title: value,
        url: '/mparents',
        icon: 'people-outline'
      })
    });
    this.translate.get('manager.menumanager.roles').subscribe(value => {
      this.managerMenu.push({
        title: value,
        url: '/mroles',
        icon: 'list'
      })
    });

  }

  goToHome(){
    var route=null;
      switch (this.mainRole) {
        case Roles.ADMIN:
          route='/tabs-manager';
          break;
        case Roles.ENSEIGNANT:
          route='/tabs-manager';
          break; 
        case Roles.FINANCIER:
            route='tabs-manager';
            break;  
        case Roles.FONDATEUR:
              route='/tabs-manager';
              break; 
        case Roles.MANAGER:
              route='/tabs-manager';
              break; 
        case Roles.PARENT:
               route='/tabs-parent';
              break; 
         case Roles.ELEVE:
                route='/tabs-parent';
                break;        
        case Roles.SURVEILLANT:
             route='/tabs-manager';
          break;  
        default:
              this.commonService.presentAlert(CommonService.WARNING,"No such role exists!");
              break;             
      }
      //this.navCtrl.navigateRoot([route]);
      this.commonService.navigateTo(route,null,CommonService.NAV_FORWARD);
  }

  ngOnDestroy() {

  }

  logOut(){
    localStorage.clear();
    this.mainRole=null;
    this.isAuth=false;
    this.navCtrl.navigateForward(['']);
  }


 


    getUserRoles(idUser:string){
      this.stringListService.setBase("users");
      this.stringListService.getEntities("/permission/findRolesByIdUser/"+idUser,true).subscribe(response =>{
        localStorage.setItem(storage_var.userRoles,JSON.stringify(response));
      }, (e: HttpErrorResponse) => {
        this.commonService.presentAlert(CommonService.WARNING,"Impossible de charger les roles utilisateurs "+this.commonService.getServerMsg(e));
      });
     }
  
     getParentRoles(idUser:string){
      this.stringListService.setBase("abonnement");
      this.stringListService.getEntities("/souscription/findAccesByIdUser/"+idUser,true).subscribe(response =>{
        console.log(response);
        localStorage.setItem(storage_var.userRoles,JSON.stringify(response));
      }, (e: HttpErrorResponse) => {
        this.commonService.presentAlert(CommonService.WARNING,"Impossible de charger les roles utilisateurs "+this.commonService.getServerMsg(e));
      });
     }
}
