export class Roles {
    public static ADMIN = "ADMIN";
    public static FONDATEUR = "FONDATEUR";
    public static MANAGER = "MANAGER";
    public static FINANCIER = "FINANCIER";
    public static SURVEILLANT = "SURVEILLANT";
    public static INTENDANT = "INTENDANT";
    public static ENSEIGNANT = "ENSEIGNANT";
    public static ELEVE = "ELEVE";
    public static PARENT = "PARENT";

    public static allRoles():string[]{
        let all= [Roles.ADMIN,Roles.FONDATEUR,Roles.MANAGER,Roles.FINANCIER,Roles.SURVEILLANT,Roles.INTENDANT,Roles.ENSEIGNANT,Roles.ELEVE,Roles.PARENT];
        return all;
    }

    public static personnel():string[]{
        let all= [Roles.FONDATEUR,Roles.MANAGER,Roles.FINANCIER,Roles.SURVEILLANT,Roles.INTENDANT,Roles.ENSEIGNANT];
        return all;
    }

    public static parentEleve():string[]{
        let all= [Roles.ELEVE,Roles.PARENT];
        return all;
    }
}