import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./welcomeprocess/welcome/welcome.module').then( m => m.WelcomePageModule)
  },
  {
    path: 'tabs-parent',
    loadChildren: () => import('./welcomeprocess/tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'tabs-manager',
    loadChildren: () => import('./welcomeprocess/tabs-manager/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'tabs-admin',
    loadChildren: () => import('./welcomeprocess/tabs-admin/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'admin-home',
    loadChildren: () => import('./welcomeprocess/admin-home/admin-home.module').then( m => m.AdminHomePageModule)
  },
  {
    path: 'welcome',
    loadChildren: () => import('./welcomeprocess/welcome/welcome.module').then( m => m.WelcomePageModule)
  },
  {
    path: 'select-school',
    loadChildren: () => import('./welcomeprocess/select-school/select-school.module').then( m => m.SelectSchoolPageModule)
  },
  {
    path: 'select-mode',
    loadChildren: () => import('./welcomeprocess/select-mode/select-mode.module').then( m => m.SelectModePageModule)
  },
  {
    path: 'authentification',
    loadChildren: () => import('./welcomeprocess/authentification/authentification.module').then( m => m.AuthentificationPageModule)
  },
  {
    path: 'metablissements',
    loadChildren: () => import('./manager/metablissements/metablissements.module').then( m => m.MetablissementsPageModule)
  },
  {
    path: 'manneescolaires',
    loadChildren: () => import('./manager/manneescolaires/manneescolaires.module').then( m => m.ManneescolairesPageModule)
  },
  {
    path: 'mbatiments',
    loadChildren: () => import('./manager/mbatiments/mbatiments.module').then( m => m.MbatimentsPageModule)
  },
  {
    path: 'msalleclasses',
    loadChildren: () => import('./manager/msalleclasses/msalleclasses.module').then( m => m.MsalleclassesPageModule)
  },
  {
    path: 'mcycles',
    loadChildren: () => import('./manager/mcycles/mcycles.module').then( m => m.McyclesPageModule)
  },
  {
    path: 'mgroupematiere',
    loadChildren: () => import('./manager/mgroupematiere/mgroupematiere.module').then( m => m.MgroupematierePageModule)
  },
  {
    path: 'mtypeevaluation',
    loadChildren: () => import('./manager/mtypeevaluation/mtypeevaluation.module').then( m => m.MtypeevaluationPageModule)
  },
  {
    path: 'mtypepersonnel',
    loadChildren: () => import('./manager/mtypepersonnel/mtypepersonnel.module').then( m => m.MtypepersonnelPageModule)
  },
  {
    path: 'mtypefrais',
    loadChildren: () => import('./manager/mtypefrais/mtypefrais.module').then( m => m.MtypefraisPageModule)
  },
  {
    path: 'mperiodes',
    loadChildren: () => import('./manager/mperiodes/mperiodes.module').then( m => m.MperiodesPageModule)
  },
  {
    path: 'mniveaux',
    loadChildren: () => import('./manager/mniveaux/mniveaux.module').then( m => m.MniveauxPageModule)
  },
  {
    path: 'mmatieres',
    loadChildren: () => import('./manager/mmatieres/mmatieres.module').then( m => m.MmatieresPageModule)
  },
  {
    path: 'mniveaumatieres',
    loadChildren: () => import('./manager/mniveaumatieres/mniveaumatieres.module').then( m => m.MniveaumatieresPageModule)
  },
  {
    path: 'meleves',
    loadChildren: () => import('./manager/meleves/meleves.module').then( m => m.MelevesPageModule)
  },
  {
    path: 'mniveaueleves',
    loadChildren: () => import('./manager/mniveaueleves/mniveaueleves.module').then( m => m.MniveauelevesPageModule)
  },
  {
    path: 'mfourniturescolaire',
    loadChildren: () => import('./manager/mfourniturescolaire/mfourniturescolaire.module').then( m => m.MfourniturescolairePageModule)
  },
  {
    path: 'mpiecejointes',
    loadChildren: () => import('./manager/admission/mpiecejointes/mpiecejointes.module').then( m => m.MpiecejointesPageModule)
  },
  {
    path: 'mconditionadmission',
    loadChildren: () => import('./manager/admission/mconditionadmission/mconditionadmission.module').then( m => m.MconditionadmissionPageModule)
  },
  {
    path: 'mdemandeadmission',
    loadChildren: () => import('./manager/admission/mdemandeadmission/mdemandeadmission.module').then( m => m.MdemandeadmissionPageModule)
  },
  {
    path: 'mcontenu',
    loadChildren: () => import('./manager/cours/mcontenu/mcontenu.module').then( m => m.McontenuPageModule)
  },
  {
    path: 'mcours',
    loadChildren: () => import('./manager/cours/mcours/mcours.module').then( m => m.McoursPageModule)
  },
  {
    path: 'mdevoirs',
    loadChildren: () => import('./manager/cours/mdevoirs/mdevoirs.module').then( m => m.MdevoirsPageModule)
  },
  {
    path: 'melevecours',
    loadChildren: () => import('./manager/cours/melevecours/melevecours.module').then( m => m.MelevecoursPageModule)
  },
  {
    path: 'memploidetemps',
    loadChildren: () => import('./manager/emploidetemps/memploidetemps/memploidetemps.module').then( m => m.MemploidetempsPageModule)
  },
  {
    path: 'meditemploidetemps',
    loadChildren: () => import('./manager/emploidetemps/meditemploidetemps/meditemploidetemps.module').then( m => m.MeditemploidetempsPageModule)
  },
  {
    path: 'mevaluations',
    loadChildren: () => import('./manager/evaluation/mevaluations/mevaluations.module').then( m => m.MevaluationsPageModule)
  },
  {
    path: 'mnotes',
    loadChildren: () => import('./manager/evaluation/mnotes/mnotes.module').then( m => m.MnotesPageModule)
  },
  {
    path: 'mfrais',
    loadChildren: () => import('./manager/fraiscolaire/mfrais/mfrais.module').then( m => m.MfraisPageModule)
  },
  {
    path: 'mpaiements',
    loadChildren: () => import('./manager/fraiscolaire/mpaiements/mpaiements.module').then( m => m.MpaiementsPageModule)
  },
  {
    path: 'mtranches',
    loadChildren: () => import('./manager/fraiscolaire/mtranches/mtranches.module').then( m => m.MtranchesPageModule)
  },
  {
    path: 'musers',
    loadChildren: () => import('./manager/users/musers/musers.module').then( m => m.MusersPageModule)
  },
  {
    path: 'mroles',
    loadChildren: () => import('./manager/users/mroles/mroles.module').then( m => m.MrolesPageModule)
  },
  {
    path: 'mpermissions',
    loadChildren: () => import('./manager/users/mpermissions/mpermissions.module').then( m => m.MpermissionsPageModule)
  },
  {
    path: 'madresses',
    loadChildren: () => import('./manager/madresses/madresses.module').then( m => m.MadressesPageModule)
  },
  {
    path: 'menseignants',
    loadChildren: () => import('./manager/menseignants/menseignants.module').then( m => m.MenseignantsPageModule)
  },
  {
    path: 'mpersonnels',
    loadChildren: () => import('./manager/mpersonnels/mpersonnels.module').then( m => m.MpersonnelsPageModule)
  },
  {
    path: 'menseignantmatieres',
    loadChildren: () => import('./manager/menseignantmatieres/menseignantmatieres.module').then( m => m.MenseignantmatieresPageModule)
  },
  {
    path: 'mparents',
    loadChildren: () => import('./manager/mparents/mparents.module').then( m => m.MparentsPageModule)
  },
  {
    path: 'mparenteleves',
    loadChildren: () => import('./manager/mparenteleves/mparenteleves.module').then( m => m.MparentelevesPageModule)
  },
  {
    path: 'mobile-print',
    loadChildren: () => import('./printing/mobile-print/mobile-print.module').then( m => m.MobilePrintPageModule)
  },
  {
    path: 'web-print',
    loadChildren: () => import('./printing/web-print/web-print.module').then( m => m.WebPrintPageModule)
  },
  {
    path: 'scheduler',
    loadChildren: () => import('./printing/scheduler/scheduler.module').then( m => m.SchedulerPageModule)
  },
  {
    path: 'bulletins',
    loadChildren: () => import('./manager/evaluation/bulletins/bulletins.module').then( m => m.BulletinsPageModule)
  },
  {
    path: 'pbulletins',
    loadChildren: () => import('./parent/evaluation/bulletins/bulletins.module').then( m => m.BulletinsPageModule)
  },
  {
    path: 'petablissements',
    loadChildren: () => import('./parent/metablissements/metablissements.module').then( m => m.MetablissementsPageModule)
  },
  {
    path: 'panneescolaires',
    loadChildren: () => import('./parent/manneescolaires/manneescolaires.module').then( m => m.ManneescolairesPageModule)
  },
  {
    path: 'pbatiments',
    loadChildren: () => import('./parent/mbatiments/mbatiments.module').then( m => m.MbatimentsPageModule)
  },
  {
    path: 'psalleclasses',
    loadChildren: () => import('./parent/msalleclasses/msalleclasses.module').then( m => m.MsalleclassesPageModule)
  },
  {
    path: 'pcycles',
    loadChildren: () => import('./parent/mcycles/mcycles.module').then( m => m.McyclesPageModule)
  },
  {
    path: 'pgroupematiere',
    loadChildren: () => import('./parent/mgroupematiere/mgroupematiere.module').then( m => m.MgroupematierePageModule)
  },
  {
    path: 'ptypeevaluation',
    loadChildren: () => import('./parent/mtypeevaluation/mtypeevaluation.module').then( m => m.MtypeevaluationPageModule)
  },
  {
    path: 'ptypepersonnel',
    loadChildren: () => import('./parent/mtypepersonnel/mtypepersonnel.module').then( m => m.MtypepersonnelPageModule)
  },
  {
    path: 'ptypefrais',
    loadChildren: () => import('./parent/mtypefrais/mtypefrais.module').then( m => m.MtypefraisPageModule)
  },
  {
    path: 'pperiodes',
    loadChildren: () => import('./parent/mperiodes/mperiodes.module').then( m => m.MperiodesPageModule)
  },
  {
    path: 'pniveaux',
    loadChildren: () => import('./parent/mniveaux/mniveaux.module').then( m => m.MniveauxPageModule)
  },
  {
    path: 'pmatieres',
    loadChildren: () => import('./parent/mmatieres/mmatieres.module').then( m => m.MmatieresPageModule)
  },
  {
    path: 'pniveaumatieres',
    loadChildren: () => import('./parent/mniveaumatieres/mniveaumatieres.module').then( m => m.MniveaumatieresPageModule)
  },
  {
    path: 'peleves',
    loadChildren: () => import('./parent/meleves/meleves.module').then( m => m.MelevesPageModule)
  },
  {
    path: 'pniveaueleves',
    loadChildren: () => import('./parent/mniveaueleves/mniveaueleves.module').then( m => m.MniveauelevesPageModule)
  },
  {
    path: 'pfourniturescolaire',
    loadChildren: () => import('./parent/mfourniturescolaire/mfourniturescolaire.module').then( m => m.MfourniturescolairePageModule)
  },
  {
    path: 'ppiecejointes',
    loadChildren: () => import('./parent/admission/mpiecejointes/mpiecejointes.module').then( m => m.MpiecejointesPageModule)
  },
  {
    path: 'pconditionadmission',
    loadChildren: () => import('./parent/admission/mconditionadmission/mconditionadmission.module').then( m => m.MconditionadmissionPageModule)
  },
  {
    path: 'pdemandeadmission',
    loadChildren: () => import('./parent/admission/mdemandeadmission/mdemandeadmission.module').then( m => m.MdemandeadmissionPageModule)
  },
  {
    path: 'pcontenu',
    loadChildren: () => import('./parent/cours/mcontenu/mcontenu.module').then( m => m.McontenuPageModule)
  },
  {
    path: 'pcours',
    loadChildren: () => import('./parent/cours/mcours/mcours.module').then( m => m.McoursPageModule)
  },
  {
    path: 'pdevoirs',
    loadChildren: () => import('./parent/cours/mdevoirs/mdevoirs.module').then( m => m.MdevoirsPageModule)
  },
  {
    path: 'pelevecours',
    loadChildren: () => import('./parent/cours/melevecours/melevecours.module').then( m => m.MelevecoursPageModule)
  },
  {
    path: 'pemploidetemps',
    loadChildren: () => import('./parent/emploidetemps/memploidetemps/memploidetemps.module').then( m => m.MemploidetempsPageModule)
  },
  {
    path: 'peditemploidetemps',
    loadChildren: () => import('./parent/emploidetemps/meditemploidetemps/meditemploidetemps.module').then( m => m.MeditemploidetempsPageModule)
  },
  {
    path: 'pevaluations',
    loadChildren: () => import('./parent/evaluation/mevaluations/mevaluations.module').then( m => m.MevaluationsPageModule)
  },
  {
    path: 'pnotes',
    loadChildren: () => import('./parent/evaluation/mnotes/mnotes.module').then( m => m.MnotesPageModule)
  },
  {
    path: 'pfrais',
    loadChildren: () => import('./parent/fraiscolaire/mfrais/mfrais.module').then( m => m.MfraisPageModule)
  },
  {
    path: 'ppaiements',
    loadChildren: () => import('./parent/fraiscolaire/mpaiements/mpaiements.module').then( m => m.MpaiementsPageModule)
  },
  {
    path: 'ptranches',
    loadChildren: () => import('./parent/fraiscolaire/mtranches/mtranches.module').then( m => m.MtranchesPageModule)
  },
  {
    path: 'pusers',
    loadChildren: () => import('./parent/users/musers/musers.module').then( m => m.MusersPageModule)
  },
  {
    path: 'proles',
    loadChildren: () => import('./parent/users/mroles/mroles.module').then( m => m.MrolesPageModule)
  },
  {
    path: 'ppermissions',
    loadChildren: () => import('./parent/users/mpermissions/mpermissions.module').then( m => m.MpermissionsPageModule)
  },
  {
    path: 'padresses',
    loadChildren: () => import('./parent/madresses/madresses.module').then( m => m.MadressesPageModule)
  },
  {
    path: 'penseignants',
    loadChildren: () => import('./parent/menseignants/menseignants.module').then( m => m.MenseignantsPageModule)
  },
  {
    path: 'ppersonnels',
    loadChildren: () => import('./parent/mpersonnels/mpersonnels.module').then( m => m.MpersonnelsPageModule)
  },
  {
    path: 'penseignantmatieres',
    loadChildren: () => import('./parent/menseignantmatieres/menseignantmatieres.module').then( m => m.MenseignantmatieresPageModule)
  },
  {
    path: 'pparents',
    loadChildren: () => import('./parent/mparents/mparents.module').then( m => m.MparentsPageModule)
  },
  {
    path: 'pparenteleves',
    loadChildren: () => import('./parent/mparenteleves/mparenteleves.module').then( m => m.MparentelevesPageModule)
  },
  {
    path: 'profil',
    loadChildren: () => import('./reglages/profil/profil.module').then( m => m.ProfilPageModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./reglages/settings/settings.module').then( m => m.SettingsPageModule)
  },
  {
    path: 'pedidion',
    loadChildren: () => import('./reglages/pedidion/pedidion.module').then( m => m.PedidionPageModule)
  },
  {
    path: 'changepwd',
    loadChildren: () => import('./reglages/changepwd/changepwd.module').then( m => m.ChangepwdPageModule)
  },
  {
    path: 'pwdforgoted',
    loadChildren: () => import('./reglages/pwdforgoted/pwdforgoted.module').then( m => m.PwdforgotedPageModule)
  },
  {
    path: 'contactus',
    loadChildren: () => import('./reglages/contactus/contactus.module').then( m => m.ContactusPageModule)
  },
  {
    path: 'changeemailandtel',
    loadChildren: () => import('./reglages/changeemailandtel/changeemailandtel.module').then( m => m.ChangeemailandtelPageModule)
  },
  {
    path: 'recover/:token',
    loadChildren: () => import('./reglages/recoverpwd/recoverpwd.module').then( m => m.RecoverpwdPageModule)
  },
  {
    path: 'offres',
    loadChildren: () => import('./abonnement/offres/offres.module').then( m => m.OffresPageModule)
  },
  {
    path: 'souscriptions',
    loadChildren: () => import('./abonnement/souscriptions/souscriptions.module').then( m => m.SouscriptionsPageModule)
  },
  {
    path: 'messaging',
    loadChildren: () => import('./manager/message/messaging/messaging.module').then( m => m.MessagingPageModule)
  },
  {
    path: 'vuecours',
    loadChildren: () => import('./manager/cours/vuecours/vuecours.module').then( m => m.VuecoursPageModule)
  },
  {
    path: 'avanceprogramme',
    loadChildren: () => import('./manager/cours/avanceprogramme/avanceprogramme.module').then( m => m.AvanceprogrammePageModule)
  },
  {
    path: 'pmessaging',
    loadChildren: () => import('./parent/message/messaging/messaging.module').then( m => m.MessagingPageModule)
  },
  {
    path: 'pvuecours',
    loadChildren: () => import('./parent/cours/vuecours/vuecours.module').then( m => m.VuecoursPageModule)
  },
  {
    path: 'pavanceprogramme',
    loadChildren: () => import('./parent/cours/avanceprogramme/avanceprogramme.module').then( m => m.AvanceprogrammePageModule)
  },
  {
    path: 'vuedevoir',
    loadChildren: () => import('./manager/cours/vuedevoir/vuedevoir.module').then( m => m.VuedevoirPageModule)
  },
  {
    path: 'vueabscence',
    loadChildren: () => import('./manager/cours/vueabscence/vueabscence.module').then( m => m.VueabscencePageModule)
  },
  {
    path: 'pvuedevoir',
    loadChildren: () => import('./parent/cours/vuedevoir/vuedevoir.module').then( m => m.VuedevoirPageModule)
  },
  {
    path: 'pvueabscence',
    loadChildren: () => import('./parent/cours/vueabscence/vueabscence.module').then( m => m.VueabscencePageModule)
  },
  {
    path: 'error-page',
    loadChildren: () => import('./error/error-page/error-page.module').then( m => m.ErrorPagePageModule)
  } ,  
  {
    path: 'licence',
    loadChildren: () => import('./manager/parametres/licence/licence.module').then( m => m.LicencePageModule)
  },
  {
    path: 'dataexpport',
    loadChildren: () => import('./manager/parametres/dataexpport/dataexpport.module').then( m => m.DataexpportPageModule)
  },
  {
    path: 'notification',
    loadChildren: () => import('./parent/parametres/notification/notification.module').then( m => m.NotificationPageModule)
  },
  {
    path: 'type-depenses',
    loadChildren: () => import('./manager/fraiscolaire/type-depenses/type-depenses.module').then( m => m.TypeDepensesPageModule)
  },
  {
    path: 'depenses',
    loadChildren: () => import('./manager/fraiscolaire/depenses/depenses.module').then( m => m.DepensesPageModule)
  },
  {
    path: 'mois',
    loadChildren: () => import('./manager/fraiscolaire/mois/mois.module').then( m => m.MoisPageModule)
  },
  {
    path: 'semaine',
    loadChildren: () => import('./manager/fraiscolaire/semaine/semaine.module').then( m => m.SemainePageModule)
  },
  {
    path: 'tranche-speciale',
    loadChildren: () => import('./manager/fraiscolaire/tranche-speciale/tranche-speciale.module').then( m => m.TrancheSpecialePageModule)
  },
  {
    path: 'niveau-typefrais',
    loadChildren: () => import('./manager/fraiscolaire/niveau-typefrais/niveau-typefrais.module').then( m => m.NiveauTypefraisPageModule)
  },
  {
    path: 'paiement-eleve',
    loadChildren: () => import('./manager/fraiscolaire/paiement-eleve/paiement-eleve.module').then( m => m.PaiementElevePageModule)
  },
  {
    path: 'paiement-item',
    loadChildren: () => import('./manager/fraiscolaire/paiement-item/paiement-item.module').then( m => m.PaiementItemPageModule)
  },
  {
    path: 'paiement-process',
    loadChildren: () => import('./manager/fraiscolaire/paiement-process/paiement-process.module').then( m => m.PaiementProcessPageModule)
  },
  {
    path: 'niveau-typefrais-create',
    loadChildren: () => import('./manager/fraiscolaire/niveau-typefrais-create/niveau-typefrais-create.module').then( m => m.NiveauTypefraisCreatePageModule)
  },
  {
    path: 'insolvables',
    loadChildren: () => import('./manager/fraiscolaire/insolvables/insolvables.module').then( m => m.InsolvablesPageModule)
  },
  {
    path: 'decisions-appreciations',
    loadChildren: () => import('./manager/parametres/decisions-appreciations/decisions-appreciations.module').then( m => m.DecisionsAppreciationsPageModule)
  },
  {path: '**', redirectTo: 'error-page'},





];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
