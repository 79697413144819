import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonService } from 'src/api/common/common.service';
import { CoreService } from 'src/api/manager/core.service';
import { ErrorManager } from 'src/errors/errorManager';
import { Utilisateur } from 'src/json_entities/user/Utilisateur';

@Injectable({
  providedIn: 'root'
})
export class UserService extends CoreService<Utilisateur>{

  constructor( http: HttpClient,  commonService:CommonService, errorManager: ErrorManager) {
    super(http,commonService,errorManager);
    super.setBase('users');
  }
}
