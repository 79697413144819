import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { APIBase } from 'src/api/APIBase';
import { storage_var } from 'src/environments/environment';
import { ErrorManager } from 'src/errors/errorManager';

@Injectable({
  providedIn: 'root'
})
export class LicenceService  extends APIBase{
  base:string="licence";

  constructor(private http: HttpClient,private errorManager: ErrorManager) {
    super();
  }

  getEntity(suffixRoute:string,withHeader:boolean): Observable<string> {
    return this.http
        .get<string>(this.getUrl() + this.base+suffixRoute, this.getHeaders(withHeader))
        .pipe(
            retry(2),
            catchError(this.errorManager.handleError)
        );
    } 

    setBase(base:string){
      this.base=base;
   }

    getHeaders(withToken:boolean): {}{
      var httpOptions;
      if(withToken){
          var token=localStorage.getItem(storage_var.token);
          httpOptions = {
              headers: new HttpHeaders({
                  'Content-Type':  'text/plain; charset=utf-8',
                  'Authorization': `Bearer `+token,
                  'Access-Control-Allow-Origin': '*',
                  'Access-Control-Allow-Methods': 'GET, POST, OPTIONS',
                  'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With'
              }),  responseType: 'text'
          }
      }else{
          httpOptions = {
              headers: new HttpHeaders({
                  'Content-Type': 'text/plain; charset=utf-8',
                  'Access-Control-Allow-Origin': '*',
                  'Access-Control-Allow-Methods': 'GET, POST, OPTIONS',
                  'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With'
              }),  responseType: 'text'
          }
      }
      return httpOptions;
    }
}