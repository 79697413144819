import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Institution } from 'src/json_entities/generic/Institution';
import { environment} from '../../environments/environment';
import { ErrorManager} from '../../errors/errorManager';
import { APIBase } from '../APIBase';
import { Etablissement } from 'src/json_entities/core/Etablissement';
import { AnneeScolaire } from 'src/json_entities/core/AnneeScolaire';
import { AlertController, LoadingController, NavController } from '@ionic/angular';
import { NavigationExtras } from '@angular/router';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { DatePipe } from '@angular/common';
import { LicenceService } from 'src/app/service/licence.service';
import { Utilisateur } from 'src/json_entities/user/Utilisateur';
import { TranslateService } from '@ngx-translate/core';
import { storage_var } from 'src/environments/environment.prod';
import { Decision } from 'src/json_entities/util/Decision';


@Injectable({
  providedIn: 'root'
})
export class CommonService extends APIBase{
  //For presentAlert
  static INFO='Info!';
  static WARNING='Warning!';
  static ERROR='Error!';
  static SUCCES='Succès!';
  //For navigateTo
  static NAV_FORWARD='1'
  static NAV_BACK='2'
  static NAV_ROOT='3'
  loading: HTMLIonLoadingElement;
  private translate: TranslateService;
  etablissement:Etablissement=JSON.parse(localStorage.getItem(Etablissement.name)) as Etablissement;
  decisions:Decision[]=[];
  appreciations:Decision[]=[];

  constructor(translate: TranslateService,private licenceService:LicenceService, public navCtrl: NavController,private http: HttpClient, private errorManager: ErrorManager,private loadingCtrl: LoadingController,private alertController: AlertController,private callNumber: CallNumber) {
       super();
       this.translate=translate;
       if(this.etablissement!=null && this.etablissement!=undefined){
        this.decisions=this.encode(this.etablissement.decisions);
        this.appreciations=this.encode(this.etablissement.appreciations);
       }
  }

  getInstitutionsByCodeEtab(codeEtab: string): Observable<HttpResponse<Institution>> {
    var url=environment.online_ip+':1990/'+environment.base
    return this.http
        .get<Institution>(url + 'institutions/findByCode/' + codeEtab, { observe: 'response' })
        .pipe(
            retry(2),
            catchError(this.errorManager.handleError)
        );
  }

  getEtablissement(): Observable<HttpResponse<Etablissement>> {
    return this.http
        .get<Etablissement>(this.getUrl() + 'core/etablissement/getEtablissement', { observe: 'response' })
        .pipe(
            retry(2),
            catchError(this.errorManager.handleError)
        );
  }

  getAnneeCourante(): Observable<HttpResponse<AnneeScolaire>> {
    return this.http
        .get<AnneeScolaire>(this.getUrl() + 'core/anneescolaire/getAnneeCourante', { observe: 'response' })
        .pipe(
            retry(2),
            catchError(this.errorManager.handleError)
        );
  }

  getIdAnneeCourante():Observable<String> {
    return this.http.get<String>(this.getUrl() + 'core/anneescolaire/getIdAnneeCourante', this.getPlainTexteHeaders(false))
    .pipe(
      retry(2),
      catchError(this.errorManager.handleError)
  );
  }

  getParentsByMatriculeEleve(matricule:string): Observable<HttpResponse<Utilisateur[]>> {
    return this.http
        .get<Utilisateur[]>(this.getUrl() + 'public/findUsersByMatriculeEleve/'+matricule, { observe: 'response' })
        .pipe(
            retry(2),
            catchError(this.errorManager.handleError)
        );
  }

  ping(): Observable<HttpResponse<string>> {
    return this.http
        .get<string>(this.getUrl()+'public/ping', { observe: 'response' })
        .pipe(
            retry(2),
            catchError(this.errorManager.handleError)
        );
  }

  isLicenceValide(): Observable<string> {
    return this.licenceService.getEntity("/isValid",true);
  }

  async presentAlert(title:string,msg:string) {
    const alert = await this.alertController.create({
      header: title,
      message: msg,
      buttons: [
        {
          text: 'Ok',
          handler: () => { }
        }
      ]
    });
    await alert.present();
    await alert.onDidDismiss();
  }

  async presentPaymentAlert(title:string,msg:string) {
    const alert = await this.alertController.create({
      header: title,
      message: msg,
      buttons: [
        {
          text: 'Ok',
          handler: () => { }
        },
        {
          text: 'Nos offres',
          handler: () => { 
            this.navCtrl.navigateBack(['offres']);
          }
        }
      ]
    });
    await alert.present();
    await alert.onDidDismiss();
  }

  async showLoading(backgroundBlock:boolean) {
    this.loading = await this.loadingCtrl.create({
     message: 'Loading...',
     translucent:backgroundBlock,
     backdropDismiss:true,
     spinner: 'lines'
   });
   
   await this.loading.present();
 }

 async dismissLoading(){
  this.loadingCtrl.getTop().then(loader => {
    if (loader) {
      loader.dismiss();
    }
  });
   //await this.loading.dismiss()
 }

 async hideLoading() {
  this.loadingCtrl.getTop().then(loader => {
    if (loader) {
      loader.dismiss();
    }
  });
}

 navigateTo(route:string,navExtras:NavigationExtras,nav_type:string){
   switch (nav_type) {
    case CommonService.NAV_FORWARD:
      if(navExtras==null) this.navCtrl.navigateForward([route]); else this.navCtrl.navigateForward([route],navExtras);
      break;
    case CommonService.NAV_BACK:
      if(navExtras==null) this.navCtrl.navigateBack([route]); else this.navCtrl.navigateBack([route],navExtras);
      break; 
    case CommonService.NAV_ROOT:
      if(navExtras==null) this.navCtrl.navigateRoot([route]); else this.navCtrl.navigateRoot([route],navExtras); 
      break;  
    default:
      this.presentAlert(CommonService.WARNING,"No such navigation exists!");
      break;             
  }
 }

 makeCall(number:string){
  this.callNumber.callNumber(number, true)
  .then(res =>{ 
  })
  .catch(err =>{ 
 });
 }

 getServerMsg(e: HttpErrorResponse):string{
  return " "+e.status+" "+e.error;
 }

 dateTimeToDate(date:Date):string{
  var  datePipe: DatePipe=new DatePipe("en-US")
  return datePipe.transform(date, "yyyy-MM-dd");
 }

 getTranslate():TranslateService{
  return this.translate;
 }


    //=========================================================generate file==========================================================
    getFile(suffixRoute:string): Observable<any> {
      var httpOptions;
          var token=localStorage.getItem(storage_var.token);
          httpOptions = {
              headers: new HttpHeaders({
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer `+token,
                  'Access-Control-Allow-Origin': '*',
                  'Access-Control-Allow-Methods': 'GET, POST, OPTIONS',
                  'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With'
              }),
              observe: 'response',
              'responseType'  : 'blob' as 'json'
          }
      
      return this.http.get<any>(this.getUrl() + this.base+suffixRoute, httpOptions);
      }


      encode(toDecode:string):Decision[]{
        var elements: Decision[]=[];
        var elementsString:string[];
        if(toDecode!=null && toDecode!=undefined){
          elementsString=toDecode.split("|");
          if(elementsString.length>0){
              elementsString.forEach(item => {
                if(item.split("@").length>2){
                  let element:Decision= new Decision();
                  element.de=Number(item.split("@")[0]);
                  element.a=Number(item.split("@")[1]);
                  element.note=item.split("@")[2];
                  elements.push(element);
                }
              });
          }
        }
        return elements;
      }
    
      decode(elements:Decision[]):string{
        var decoded="";
        elements.forEach(item => {
          decoded=decoded+item.de+"@"+item.a+"@"+item.note+"|"
        });
        if(decoded.length==0) decoded="-";
        return decoded;
      }

      getDecision(moyenne:number):string{
        var value:string="";
        this.decisions.forEach(item => {
          if(moyenne==20 && item.a==20){
            value= item.note;
            return;
           }
          if(moyenne>=item.de && moyenne<item.a){
            value= item.note;
            return;
          }
        });
        return value;
      }

       getAppreciation(note:number):string{
        var value:string="";
        this.appreciations.forEach(item => {
          if(note==20 && item.a==20){
             value= item.note;
             return;
          }
          if(note>=item.de && note<item.a){
            value= item.note;
            return;
          }
        });
        return value;
      }

/*       getAppreciation2(appreciations:Decision[],note:number):Promise<string>{
        const promise: Promise<string> = new Promise((resolve, reject) => {
          console.log(appreciations);
          appreciations.forEach(item => {
            if(note>=item.de && note<=item.a){
              resolve(item.note);
            }
          });
           reject("");
        });
        return promise;
      } */

}
