import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonService } from 'src/api/common/common.service';
import { CoreService } from 'src/api/manager/core.service';
import { ErrorManager } from 'src/errors/errorManager';

@Injectable({
  providedIn: 'root'
})
export class StringListService extends CoreService<String>{

  constructor( http: HttpClient,  commonService:CommonService, errorManager: ErrorManager) {
    super(http,commonService,errorManager);
  }

  setBase(base: string): void {
    super.setBase(base);
  }
}

