
export const environment = {
  production: true,
  theme: 'light', // 'light' or 'dark'
  online_ip:'https://synaps-edu.com',
  local_ip:'http://localhost',
  //local_ip:'https://135.125.132.247',
  online_port:'1963',
  local_port:'1937',
  base:'api/',
  mode:'prod'
};

export const storage_var= {
  isonline:'isonline',
  institution:'Institution',
  etablissement:'Etablissement',
  anneescolaire:'AnneeScolaire',
  mainRole:'mainRole',
  userId:'userId',
  userRoles:'userroles',
  userOwner:'userOwner',
  apprenants:'apprenants',
  token:'token'
}  