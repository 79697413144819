import { Adresse } from "./Adresse";
import { Id } from "./Id";

export class Etablissement extends  Id {

      libelle:string=null;
      libelleen:string=null;

      code:string=null;
      logo:string=null;
      devise:string=null;
      tel1:string=null;
      tel2:string=null;
      telFix1:string=null;
      telFix2:string=null;
      latitude:string=null;
      longitude:string=null;
      omAccount:string=null;
      momoAccount:string=null;
      appreciations:string=null;
      decisions:string=null;
      rib:string=null;
      ip:string=null;
      port:number;
      email:string=null;
      
      adresse:Adresse = new Adresse();
      
}