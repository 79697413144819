// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  theme: 'light', // 'light' or 'dark'
  online_ip:'https://synaps-edu.com',
  local_ip:'http://localhost',
  local_conf:'http://synaps.local',
  //local_ip:'https://135.125.132.247',
  online_port:'1963',
  local_port:'1937',
  base:'api/',
  mode:'prod'
};

export const storage_var= {
  isonline:'isonline',
  institution:'Institution',
  etablissement:'Etablissement',
  anneescolaire:'AnneeScolaire',
  mainRole:'mainRole',
  userId:'userId',
  userRoles:'userroles',
  userOwner:'userOwner',
  apprenants:'apprenants',
  token:'token'
}  


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
