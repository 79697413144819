import { Id } from "./Id";
import { Etablissement } from "./Etablissement";
import { Personne } from "./Personne";

export class Adresse extends Id{
      rue:string=null;
      numeroRue:number;
      ville:string=null;
      codePostale:number;
      pays:string=null;
      etablissement:Etablissement;
      personne:Personne;

 }